import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e653803c = () => interopDefault(import('../pages/ajuda-fgts.vue' /* webpackChunkName: "pages/ajuda-fgts" */))
const _0df08104 = () => interopDefault(import('../pages/fgts-como-ser-optante.vue' /* webpackChunkName: "pages/fgts-como-ser-optante" */))
const _b45dc0d0 = () => interopDefault(import('../pages/fgts-habilitar-instituicao.vue' /* webpackChunkName: "pages/fgts-habilitar-instituicao" */))
const _04246ced = () => interopDefault(import('../pages/simulacao-fgts.vue' /* webpackChunkName: "pages/simulacao-fgts" */))
const _3e41ea78 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ajuda-fgts",
    component: _e653803c,
    name: "ajuda-fgts"
  }, {
    path: "/fgts-como-ser-optante",
    component: _0df08104,
    name: "fgts-como-ser-optante"
  }, {
    path: "/fgts-habilitar-instituicao",
    component: _b45dc0d0,
    name: "fgts-habilitar-instituicao"
  }, {
    path: "/simulacao-fgts",
    component: _04246ced,
    name: "simulacao-fgts"
  }, {
    path: "/",
    component: _3e41ea78,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
